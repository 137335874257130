import { PROCESS_TEXT_REQUEST, PROCESS_TEXT_SUCCESS, PROCESS_TEXT_FAILURE} from '../actions/types';
import testPic from '../assets/img/generic/space-IA.jpg';

// Function to convert an image to an ArrayBuffer


const initialState = {
  images: [],
  loading: false,
  error: null,
};

const imagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROCESS_TEXT_REQUEST:
      return { ...state, loading: true};
    case PROCESS_TEXT_SUCCESS:
      return { ...state, loading: false, images: action.payload };
    case PROCESS_TEXT_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default imagesReducer;