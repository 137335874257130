import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { Button, CustomInput, Row, Input, Modal, ModalHeader, ButtonGroup, Label, Media } from 'reactstrap'; // Badge was included
import AppContext from '../../context/Context';
import defaultModeImg from '../../assets/img/generic/bulkText-light.jpg';
import darkModeImg from '../../assets/img/generic/bulkText-dark.jpg';
import Flex from '../common/Flex';
import ScrollBarCustom from '../common/ScrollBarCustom';
import { LogoutUser } from '../../actions/index';
//import VerticalNavRadioBtn from './VerticalNavStyleRadioBtn';

const SidePanelModal = () => {
  const {
    isOpenSidePanel,
    toggleModal,
    isDark,
    setIsDark,
    setIsOpenSidePanel,
  } = useContext(AppContext);

  const auth = useSelector((state) => {return state.auth})

  const dispatch = useDispatch();
  



  return (
    <Modal
      isOpen={isOpenSidePanel}
      toggle={toggleModal}
      modalClassName="overflow-hidden modal-fixed-right modal-theme"
      className="modal-dialog-vertical"
      contentClassName="vh-100 border-0"
    >
      <ModalHeader tag="div" className="" toggle={toggleModal}>
        <div className="py-1 flex-grow-1">
          <h5 className={isDark ? 'text-white' : ''}>
            <FontAwesomeIcon icon="palette" className="mr-2 fs-0" />
            ImageArt
          </h5>
        </div>
      </ModalHeader>
      <div>
     
      </div>
      <ScrollBarCustom
        className="modal-body"
        contentProps={{
          renderer: ({ elementRef, ...restProps }) => (
            <span
              {...restProps}
              ref={elementRef}
              className={classNames('p-card position-absolute', { 'border-left': isDark })}
            />
          )
        }}
      >
 
 <Flex justify="between">
              <Media className="flex-grow-1">
                <FontAwesomeIcon icon="cog" className="mr-2" color="falcon-primary" transform="grow-1" />
                <Media body>
                  <h5 className="fs-0">Account Settings</h5>
                  <p className="fs--1 mb-0">Billing, Details, etc...</p>
                </Media>
              </Media>
              <Link to={!auth ? "/authentication/basic/register" : "/account/account-settings"}>
          <Button size="sm" color="primary" onClick={toggleModal}>
          <FontAwesomeIcon icon="cog" className="mr-1" /> Account
          </Button>
        </Link>
            </Flex>
      
       
          
            <hr />
            <Flex justify="between">
              <Media className="flex-grow-1">
                <FontAwesomeIcon icon="image" className="mr-2" color="falcon-primary" transform="grow-1" />
                <Media body>
                  <h5 className="fs-0">Gallery</h5>
                  <p className="fs--1 mb-0">View saved art</p>
                </Media>
              </Media>
              <Link to={!auth ? "/authentication/basic/register" : "/image-gallery"}>
          <Button size="sm" color="primary" onClick={toggleModal}>
          <FontAwesomeIcon icon="image" className="mr-1" /> Gallery
          </Button>
        </Link>
            </Flex>

            <hr />

          { auth && (
            <div>
            <Flex justify="between">
              <Media className="flex-grow-1">
                <Media body>
                  <h5 className="fs-0">Log out</h5>
                </Media>
              </Media>
                <Button size="sm" color="primary" onClick={() => {return dispatch(LogoutUser()), window.location.href="https://www.imageart.ai"} } > Log out
                  <FontAwesomeIcon icon="chevron-right" className="ml-2" /> 
                </Button>
            </Flex>
          
            <hr />
            </div>
          )}


        <h5 className="fs-0">Color Scheme</h5>
        <ButtonGroup className="btn-group-toggle btn-block">
          <Button color="theme-default" className={classNames('custom-radio-success', { active: !isDark })}>
            <Label for="theme-mode-default" className="cursor-pointer hover-overlay">
              <img className="w-100" src={defaultModeImg} alt="" />
            </Label>
            <CustomInput
              type="radio"
              id="theme-mode-default"
              label="Light"
              checked={!isDark}
              onChange={({ target }) => setIsDark(!target.checked)}
            />
          </Button>
          <Button color="theme-dark" className={classNames('custom-radio-success', { active: isDark })}>
            <Label for="theme-mode-dark" className="cursor-pointer hover-overlay">
              <img className="w-100" src={darkModeImg} alt="" />
            </Label>
            <CustomInput
              type="radio"
              id="theme-mode-dark"
              label="Dark"
              checked={isDark}
              onChange={({ target }) => setIsDark(target.checked)}
            />
          </Button>
        </ButtonGroup>
   
     { auth && (
      <div>
        <hr />
        <div className="text-center mt-5">
          {
         // <img src={settings} alt="settings" width={120} className="mb-4" />
          }
          <h5>Change plan</h5>
          <p className="fs--1">Get credits and start building your gallery.</p>
          <Link to="/get-credits">
          <Button color="primary" onClick={toggleModal}>
          <FontAwesomeIcon icon="chevron-left" className="mr-1" /> View Credits/Plans
          </Button>
        </Link>
        </div>
      </div>
     )}
      
      </ScrollBarCustom>
    </Modal>
  );
};


export default SidePanelModal;
