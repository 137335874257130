import React, { useState, useEffect } from 'react';
import { Button, TabContent, CustomInput, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import FalconCardHeader from '../common/FalconCardHeader';
import { EDIT_TAB } from '../../actions/types';
import boyImage1 from '../../assets/img/generic/Boy-1.jpg';
import boyImage2 from '../../assets/img/generic/Boy-2.jpg';
import boyImage3 from '../../assets/img/generic/Boy-3.jpg';
import boyImage4 from '../../assets/img/generic/Boy-4.jpg';
import girlImage1 from '../../assets/img/generic/Girl-1.jpg';
import girlImage2 from '../../assets/img/generic/Girl-2.jpg';
import girlImage3 from '../../assets/img/generic/Girl-3.jpg';
import girlImage4 from '../../assets/img/generic/Girl-4.jpg';
import kidImage1 from '../../assets/img/generic/Kid-1.jpg';
import kidImage2 from '../../assets/img/generic/Kid-2.jpg';
import kidImage3 from '../../assets/img/generic/Kid-3.jpg';
import kidImage4 from '../../assets/img/generic/Kid-4.jpg';
import dogImage1 from '../../assets/img/generic/Dog-1.jpeg';
import dogImage2 from '../../assets/img/generic/Dog-2.jpeg';
import dogImage3 from '../../assets/img/generic/Dog-3.jpeg';
import dogImage4 from '../../assets/img/generic/Dog-4.jpeg';
import ImageRow from './ImageRow';


const ExampleTabs = () => {
  const fromImageTab = useSelector((state) => state.fromImageTab)
  const [activeTab, setActiveTab] = useState(fromImageTab ? fromImageTab : "1");
  const [tabName, setTabName] = useState('Boy');
  const [showContent, setShowContent] = useState(false);
  const dispatch = useDispatch()
  

  const BoyImages = [
      boyImage1,
      boyImage2,
      boyImage3,
      boyImage4
  ]

  const GirlImages = [
    girlImage1,
    girlImage2,
    girlImage3,
    girlImage4
]

const DogImages = [
  dogImage1,
  dogImage2,
  dogImage3,
  dogImage4
]

const KidImages = [
  kidImage1,
  kidImage2,
  kidImage3,
  kidImage4
]

  const toggle = tab => {
    if(activeTab !== tab) { return dispatch({type: EDIT_TAB, payload : tab}), setActiveTab(tab)};
  }
 

  return (
    <div>
      <Button style={{backgroundColor: 'transparent'}} className="text-black d-flex btn btn-sm block badge-soft-primary border-0 mx-3 mb-2" >See Examples<CustomInput
  type="switch"
  className="mx-3"
  id="exampleSwitch" // It's a good practice to give an id to the CustomInput component
  onChange={() => setShowContent(!showContent)}
/></Button>
{ showContent && (
  <div>
      <Nav className="flex-md-row" tabs>
        <NavItem className="cursor-pointer">
          <NavLink
            className={classnames({ 'navLinkActive font-weight-bold': activeTab === '1' })}
            onClick={() => { toggle('1'); setTabName('Boy') }}
          >
            <span className={`text-black fs-1`}>Boy</span>
            <div className={'neonBlueBar'}></div>
          </NavLink>
        </NavItem>
        <NavItem className="cursor-pointer">
          <NavLink
            className={classnames({ 'navLinkActive font-weight-bold': activeTab === '2' })}
            onClick={() => { toggle('2'); setTabName('Girl') }}
          >
           <span className={`text-black fs-1`}>Girl </span>
           <div className={'neonBlueBar'}></div>
          </NavLink>
        </NavItem>
        <NavItem className="cursor-pointer">
          <NavLink
            className={classnames({ 'navLinkActive font-weight-bold': activeTab === '3' })}
            onClick={() => { toggle('3'); setTabName('Pets') }}
          >
            <span className={`text-black fs-1`}>Pets </span>
            <div className={'neonBlueBar'}></div>
          </NavLink>
        </NavItem>
        <NavItem className="cursor-pointer">
          <NavLink
            className={classnames({ 'navLinkActive font-weight-bold': activeTab === '4' })}
            onClick={() => { toggle('4'); setTabName('Child') }}
          >
            <span className={`text-black fs-1`}>Child </span>
            <div className={'neonBlueBar'}></div>
          </NavLink>
        </NavItem>
       
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col sm="12">
                <ImageRow images={BoyImages} />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row>
            <Col >
            <ImageRow images={GirlImages} />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="3">
          <Row>
            <Col >
            <ImageRow images={DogImages} />  
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="4">
          <Row>
            <Col >
            <ImageRow images={KidImages} />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="5">
          <Row>
            <Col >
                     
            </Col>
          </Row>
        </TabPane>
      </TabContent>
      </div>
)}
    </div>
  );
}



export default ExampleTabs;

