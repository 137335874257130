import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Main from './Main';
import './helpers/initFA';
import { Provider} from "react-redux";
import { createStore, applyMiddleware} from "redux";
import reducers from './reducers';
import reduxThunk from "redux-thunk";
import { composeWithDevTools } from 'redux-devtools-extension'; // THis is how it was before, 
//const store = createStore(reducers, composeWithDevTools(applyMiddleware(reduxThunk))); When composeWithDevTools is added, the mobile browers will show blank page at deployment. 

const store = createStore(reducers, applyMiddleware(reduxThunk));

ReactDOM.render(
  <Provider store={store}>
  <Main>
    <App />
  </Main>
  </Provider>
  
  ,
  document.getElementById('main')
);


//console.log('Environment is', process.env.NODE_ENV) // this automatically set by create react app
