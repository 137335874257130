export const FETCH_USER = 'fetch_user';
export const FETCH_REG_USER = 'fetch_reg_user';
export const DELETE_USER = 'delete_user';

export const GET_PROFILE_INFO = 'get_profile_info';
export const LOGOUT_USER = 'logout_user';

export const GET_TWILIO_NUMBER = 'get_twilio_number';

export const USER_UPDATE_REQUEST = 'user_update_request';
export const USER_UPDATE_SUCCESS = 'user_update_success';
export const USER_UPDATE_FAIL = 'user_update_fail';

export const SET_EMAIL = 'set_email';
export const SET_PASS = 'set_pass';


//-------------------------------------------------------------------------------------------------Credit create--------------------------------------
export const CREDIT_CREATE_REQUEST = 'credit_create_request';
export const CREDIT_CREATE_SUCCESS = 'credit_create_success';
export const CREDIT_CREATE_FAIL = 'credit_create_fail';
export const CREDIT_CREATE_RESET = 'credit_create_reset';

export const CLIENT_SECRET_REQUEST = 'client_secret_request';
export const CLIENT_SECRET_SUCCESS = 'client_secret_success';
export const CLIENT_SECRET_FAIL = 'client_secret_fail';
export const CLIENT_SECRET_RESET = 'client_secret_reset';

export const CREATE_TOKEN_REQUEST = 'create_token _request';
export const CREATE_TOKEN_SUCCESS = 'create_token_success';
export const CREATE_TOKEN_FAIL = 'create_token_fail';
export const CREATE_TOKEN_RESET = 'create_token_reset';

export const EDIT_TAB = 'edit_tab'


export const FETCH_IMAGES_REQUEST = 'FETCH_IMAGES_REQUEST';
export const FETCH_IMAGES_SUCCESS = 'FETCH_IMAGES_SUCCESS';
export const FETCH_IMAGES_FAILURE = 'FETCH_IMAGES_FAILURE';

export const FETCH_PROMPTS_REQUEST = 'fetch_prompts_request';
export const FETCH_PROMPTS_SUCCESS = 'fetch_prompts_success';
export const FETCH_PROMPTS_FAILURE = 'fetch_prompts_failure';

export const PROMPT_DELETE_REQUEST = 'prompt_delete_request';
export const PROMPT_DELETE_SUCCESS = 'prompt_delete_success';
export const PROMPT_DELETE_FAILURE = 'prompt_delete_failure';


export const PLAN_UPDATE_STATE = 'plan_update_state';
export const PLAN_UPDATE_STATE_RESET = 'plan_update_state_reset';

export const SUBSCRIPTION_CANCEL_REQUEST = 'subscription_cancel_request';
export const SUBSCRIPTION_CANCEL_SUCCESS = 'subscription_cancel_success';
export const SUBSCRIPTION_CANCEL_FAIL = 'subscription_cancel_fail';

export const CARD_SECRET_REQUEST = 'card_secret_request';
export const CARD_SECRET_SUCCESS = 'card_secret_success';
export const CARD_SECRET_FAIL = 'card_secret_fail';

export const CONFIRM_SETUP_INTENT_REQUEST = 'confirm_setup_intent_request';
export const CONFIRM_SETUP_INTENT_SUCCESS = 'confirm_setup_intent_success';
export const CONFIRM_SETUP_INTENT_FAIL = 'confirm_setup_intent_fail';

export const PAYMENT_METHOD_DELETE_REQUEST = 'payment_method_delete_request';
export const PAYMENT_METHOD_DELETE_SUCCESS = 'payment_method_delete_success';
export const PAYMENT_METHOD_DELETE_FAIL = 'payment_method_delete_fail';

export const DEFAULT_PAY_METHOD_CREATE_REQUEST = 'payment_method_delete_request';
export const DEFAULT_PAY_METHOD_CREATE_SUCCESS = 'payment_method_delete_success';
export const DEFAULT_PAY_METHOD_CREATE_FAIL = 'payment_method_delete_fail';


export const LIST_INVOICES = 'list_invoices';

export const PROCESS_TEXT_REQUEST = 'process_text_request';
export const PROCESS_TEXT_SUCCESS = 'process_text_success';
export const PROCESS_TEXT_FAILURE = 'process_text_failure';

//-----------------------------------------------------------------------------------------------------------------------------------------------------------------------------

//-----------------------------------------------------------------------------------------------------------------------------------------------------------------------------

export const ACCOUNT_DELETE_REQUEST = 'account_delete_request';
export const ACCOUNT_DELETE_SUCCESS = 'account_delete_success';
export const ACCOUNT_DELETE_FAIL = 'account_delete_fail';

export const IMAGE_DELETE_REQUEST = 'image_delete_request';
export const IMAGE_DELETE_SUCCESS = 'image_delete_success';
export const IMAGE_DELETE_FAIL = 'image_delete_fail';
export const IMAGE_DELETE_RESET = 'image_delete_reset';

export const FORGOT_PASSWORD_CREATE_REQUEST = 'forgot_password_create_request';
export const FORGOT_PASSWORD_CREATE_SUCCESS = 'forgot_password_create_success';
export const FORGOT_PASSWORD_CREATE_FAIL = 'forgot_password_create_fail';

export const UPDATE_PASSWORD_REQUEST = 'update_password_request';
export const UPDATE_PASSWORD_SUCCESS = 'update_password_success';
export const UPDATE_PASSWORD_FAIL = 'update_password_fail';
export const UPDATE_PASSWORD_RESET = 'update_password_reset';

export const PROCESS_IMAGE_REQUEST = 'process_image_request';
export const PROCESS_IMAGE_SUCCESS= 'process_image_success';
export const PROCESS_IMAGE_FAILURE= 'process_image_failure';

export const PAYMENT_REQUEST = 'payment_request';
export const PAYMENT_SUCCESS = 'payment_success';
export const PAYMENT_FAILURE = 'payment_failure';
export const PAYMENT_RESET = 'payment_reset';

export const UPDATE_ROUTE = 'update_route';


export const POST_CREATE_REQUEST = 'post-create-request'
export const POST_CREATE_SUCCESS = 'post-create-success'
export const POST_CREATE_FAIL = 'post-create-fail'
export const POST_CREATE_RESET = 'post-create-reset'
