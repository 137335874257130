import { LIST_INVOICES } from '../actions/types';


export default function (state = [], action) {
    switch (action.type) {
        // when we logout, this action.payload is an empty string so lets do || false 
        case LIST_INVOICES:
            return action.payload  
            default:
            return state;
    }
}