import { PAYMENT_REQUEST, PAYMENT_SUCCESS, PAYMENT_FAILURE, PAYMENT_RESET} from '../actions/types';



const paymentReducer = (state = {}, action) => {
  switch (action.type) {
    case PAYMENT_REQUEST:
      return { loading: true };
    case PAYMENT_SUCCESS:
      return { loading: false, success: true };
    case PAYMENT_FAILURE:
      return { loading: false };
      case PAYMENT_RESET:
      return { };
    default:
      return state;
  }
};

export default paymentReducer;