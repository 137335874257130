
import { version } from './config';


export const imageRoutes = {
  name: 'From Image',
  to: '/from-image',
  exact: true,
  icon: 'image'
};

export const textRoutes = {
  name: 'From Text',
  to: '/from-text',
  exact: true,
  icon: 'text-height'
};

export const galleryRoutes = {
  name: 'Gallery',
  to: '/image-gallery',
  exact: true,
  icon: 'icons'
}

export const authenticationRoutes = {
  name: 'Authentication',
  to: '/authentication',
  icon: 'lock',
  children: [
    {
      to: '/authentication/basic',
      name: 'Basic',
      children: [
        { to: '/authentication/basic/login', name: 'Login' },
        { to: '/authentication/basic/logout', name: 'Logout' },
        { to: '/authentication/basic/register', name: 'Register' },
        { to: '/authentication/basic/forget-password', name: 'Forgot password' },
        { to: '/authentication/basic/password-reset', name: 'Reset password' },
        { to: '/authentication/basic/confirm-mail', name: 'Confirm mail' },
        { to: '/authentication/basic/lock-screen', name: 'Lock screen' }
      ]
    },
    {
      to: '/authentication/card',
      name: 'Card',
      children: [
        { to: '/authentication/card/login', name: 'Login' },
        { to: '/authentication/card/logout', name: 'Logout' },
        { to: '/authentication/card/register', name: 'Register' },
        { to: '/authentication/card/forget-password', name: 'Forgot password' },
        { to: '/authentication/card/password-reset', name: 'Reset password' },
        { to: '/authentication/card/confirm-mail', name: 'Confirm mail' },
        { to: '/authentication/card/lock-screen', name: 'Lock screen' }
      ]
    },
    {
      to: '/authentication/split',
      name: 'Split',
      children: [
        { to: '/authentication/split/login', name: 'Login' },
        { to: '/authentication/split/logout', name: 'Logout' },
        { to: '/authentication/split/register', name: 'Register' },
        { to: '/authentication/split/forget-password', name: 'Forgot password' },
        { to: '/authentication/split/password-reset', name: 'Reset password' },
        { to: '/authentication/split/confirm-mail', name: 'Confirm mail' },
        { to: '/authentication/split/lock-screen', name: 'Lock screen' }
      ]
    },
    {
      to: '/authentication/wizard',
      name: 'Wizard'
    }
  ]
};


export const pageRoutes = {
  name: 'Pages',
  to: '/pages',
  icon: 'copy',
  children: [
    { to: '/pages/activity', name: 'Activity' },
    { to: '/pages/associations', name: 'Associations' },
    { to: '/pages/billing', name: 'Billing' },
    { to: '/pages/customer-details', name: 'Customer details' },
    { to: '/pages/event-detail', name: 'Event detail' },
    { to: '/pages/event-create', name: 'Event create' },
    { to: '/pages/events', name: 'Events' },
    { to: '/pages/faq', name: 'Faq' },
    { to: '/pages/invoice', name: 'Invoice' },
    { to: '/pages/invite-people', name: 'Invite people' },
    { to: '/pages/notifications', name: 'Notifications' },
    { to: '/pages/people', name: 'People' },
    { to: '/pages/pricing', name: 'Pricing' },
    { to: '/pages/pricing-alt', name: 'Pricing alt' },
    { to: '/pages/profile', name: 'Profile' },
    { to: '/pages/settings', name: 'Settings' },
    { to: '/pages/starter', name: 'Starter' },
    {
      to: '/errors',
      name: 'Errors',
      children: [{ to: '/errors/404', name: '404' }, { to: '/errors/500', name: '500' }]
    }
  ]
};
export const widgetsRoutes = {
  name: 'Widgets',
  to: '/widgets',
  exact: true,
  icon: 'poll'
};




export const kanbanRoutes = {
  name: 'Kanban',
  to: '/kanban',
  exact: true,
  icon: ['fab', 'trello']
};


export const componentRoutes = {
  name: 'Components',
  to: '/components',
  icon: 'puzzle-piece',
  children: [
    { to: '/components/alerts', name: 'Alerts' },
    { to: '/components/accordions', name: 'Accordions' },
    {
      to: '/components/autocomplete',
      name: 'Autocomplete'
    },
    { to: '/components/avatar', name: 'Avatar' },
    { to: '/components/badges', name: 'Badges' },
    { to: '/components/backgrounds', name: 'Backgrounds' },
    { to: '/components/breadcrumb', name: 'Breadcrumb' },
    { to: '/components/buttons', name: 'Buttons' },
    { to: '/components/cards', name: 'Cards' },
    {
      to: '/components/cookie-notice',
      name: 'Cookie notice'
    },
    { to: '/components/collapses', name: 'Collapses' },
    {
      to: '/components/carousel',
      name: 'Carousel'
    },
    { to: '/components/dropdowns', name: 'Dropdowns' },
    { to: '/components/forms', name: 'Forms' },
    { to: '/components/listgroups', name: 'List groups' },
    { to: '/components/modals', name: 'Modals' },
    { to: '/components/navs', name: 'Navs' },
    {
      to: '/components',
      name: 'Navbar',
      children: [
        { to: '/components/navbars', name: 'Default' },
        {
          to: '/components/navbar-vertical',
          name: 'Vertical'
        },
        {
          to: '/components/navbar-top',
          name: 'Top'
        },
        {
          to: '/components/combo',
          name: 'Combo'
        }
      ]
    },
    { to: '/components/pageheaders', name: 'Page headers' },
    { to: '/components/paginations', name: 'Paginations' },
    { to: '/components/popovers', name: 'Popovers' },
    { to: '/components/progress', name: 'Progress' },
    { to: '/components/sidepanel', name: 'Sidepanel' },
    { to: '/components/spinners', name: 'Spinners' },
    {
      to: '/components/tab',
      name: 'Tabs',
      badge: {
        text: `New`,
        color: 'soft-success'
      }
    },
    { to: '/components/tables', name: 'Tables' },
    { to: '/components/tooltips', name: 'Tooltips' }
  ]
};



export const utilityRoutes = {
  name: 'Utilities',
  to: '/utilities',
  icon: ['fab', 'hotjar'],
  children: [
    { to: '/utilities/borders', name: 'Borders' },
    { to: '/utilities/clearfix', name: 'Clearfix' },
    { to: '/utilities/closeIcon', name: 'Close icon' },
    { to: '/utilities/colors', name: 'Colors' },
    { to: '/utilities/display', name: 'Display' },
    { to: '/utilities/embed', name: 'Embed' },
    { to: '/utilities/figures', name: 'Figures' },
    { to: '/utilities/flex', name: 'Flex' },
    { to: '/utilities/grid', name: 'Grid' },
    { to: '/utilities/sizing', name: 'Sizing' },
    { to: '/utilities/spacing', name: 'Spacing' },
    { to: '/utilities/stretchedLink', name: 'Stretched link' },
    { to: '/utilities/typography', name: 'Typography' },
    { to: '/utilities/verticalAlign', name: 'Vertical align' },
    { to: '/utilities/visibility', name: 'Visibility' }
  ]
};



export const accountRoutes = {
  name: 'Account',
  to: '/account',
  icon: 'user',
  children: [
    {to: '/account/account-settings', name: 'Account Settings'},
    {to: '/account/invoices', name: 'Invoices'}
  ]
};



export const supportRoutes = {
  name: 'Help & Support',
  to: '/support',
  icon: 'question',
  exact: true
};




export default [
  imageRoutes,
  textRoutes,
  galleryRoutes,
  accountRoutes,

];
