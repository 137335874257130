import {
    FORGOT_PASSWORD_CREATE_REQUEST,
    FORGOT_PASSWORD_CREATE_SUCCESS,
    FORGOT_PASSWORD_CREATE_FAIL,
    UPDATE_PASSWORD_REQUEST,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_PASSWORD_FAIL
} from './types';


import axios from 'axios'



export const resetPassword = (email) => async (dispatch) => {
    console.log(email)
   try {
           dispatch({type: FORGOT_PASSWORD_CREATE_REQUEST})

    await axios({
          url:"https://my.imageart.ai/password-reset",
          method:"POST",
          data: {email}
        })
        dispatch({type: FORGOT_PASSWORD_CREATE_SUCCESS})
    
   } catch (error) {
          dispatch({type: FORGOT_PASSWORD_CREATE_FAIL,
          payload:
          error.message && error.response.data.message 
          ? error.response.data.message :
          error.message
          })

   }
}


export const updateDBPassword = (id, password) => async (dispatch) => {
    try {
        dispatch({type: UPDATE_PASSWORD_REQUEST})

    await axios({
          url:"https://my.imageart.ai/update-password",
          method:"POST",
          data: {id, password}
        })
        dispatch({type: UPDATE_PASSWORD_SUCCESS})
        


    } catch(error) {
        dispatch({type: UPDATE_PASSWORD_FAIL,
            payload:
            error.message && error.response.data.message 
            ? error.response.data.message :
            error.message
            })
    }
}


