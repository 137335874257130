import { EDIT_TAB } from '../actions/types';


export default function (state = "1", action) {
    switch (action.type) {
        // when we logout, this action.payload is an empty string so lets do || false 
        case EDIT_TAB:
            return action.payload  
            default:
            return state;
    }
}