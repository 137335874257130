import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const ImageRow = ({ images }) => {
  const imageStyle = {
    borderRadius: '5px',
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'cover',
  };

  return (
    <div>
    <Container>
      <Row className="justify-content-center">
        {images?.map((image, index) => (
          <Col key={index} xs="3" className="mb-3">
            <img src={image} alt={`Image ${index}`} style={imageStyle} />
          </Col>
        ))}
      </Row>
    </Container>
    </div>
  );
};

export default ImageRow;