import { POST_CREATE_REQUEST, POST_CREATE_SUCCESS, POST_CREATE_FAIL, POST_CREATE_RESET} from '../actions/types';

export default function postCreateReducer(state = {}, action) {
    switch(action.type) {
        case POST_CREATE_REQUEST:
            return { loading: true }
        case POST_CREATE_SUCCESS:
            return { loading: false, success: true, newPost: action.payload}
        case POST_CREATE_FAIL:
            return { loading: false, error: action.payload }
        case POST_CREATE_RESET:
            return {}
        default:
            return state
    }
}