import { UPDATE_ROUTE } from '../actions/types';
    
    export default function updateRouteReducer(state = '', action) {
        switch(action.type) {
            case UPDATE_ROUTE:
                return action.payload
           
            default:
                return state
        }
    }