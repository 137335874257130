import { FETCH_PROMPTS_REQUEST, FETCH_PROMPTS_SUCCESS, FETCH_PROMPTS_FAILURE, PROMPT_DELETE_SUCCESS} from '../actions/types';

const initialState = {
  prompts: [],
  loading: false,
  error: null,
};

const promptsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROMPTS_REQUEST:
      return { ...state, loading: true };
    case FETCH_PROMPTS_SUCCESS:
        const existingIds = new Set(state.prompts.map((prompt) => prompt._id));
        const newPrompts = action.payload.filter((prompt) => !existingIds.has(prompt._id));
        
     
      return { ...state, loading: false, prompts: [...state.prompts, ...newPrompts] };
    case FETCH_PROMPTS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case PROMPT_DELETE_SUCCESS:
      return {
        ...state,
        prompts: state.prompts.filter((prompt) => prompt._id !== action.payload),
      };
    default:
      return state;
  }
};

export default promptsReducer;