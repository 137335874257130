import React, { useState, useEffect } from 'react'
import FalconDropzone from '../common/FalconDropzone';
import { Card, CardBody, Label, CustomInput, Tooltip, CardImg, CardTitle, Modal, ModalHeader, ModalBody, CardText, Container, Row, Col, Button } from 'reactstrap';
import { useHistory, useLocation } from 'react-router-dom';
import ExampleTabs from './ExampleTabs';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FalconCardHeader from '../common/FalconCardHeader';
import 'rc-slider/assets/index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useWindowDeminsions from '../../hooks/useWindowDimensions'
import Girl1Pic from '../../assets/img/generic/Girl-1.jpg'
import Girl2Pic from '../../assets/img/generic/Girl-2.jpg';
import { processImageFunc } from '../../actions/index';
import Lottie from 'react-lottie';
import Slider from 'rc-slider';
import animationData from '../auth/wizard/lottie/13366-no-result-clouds.json';
import PhotographicPic from '../../assets/img/generic/PhotographicPic1.png';
import AnalogFilmPic from '../../assets/img/generic/analogFilmPic1.png';
import IsometricPic from '../../assets/img/generic/isometricPic1.png';
import CinematicPic from '../../assets/img/generic/cinematicPic1.png';
import ComicBookPic from '../../assets/img/generic/comicBookPic1.png';
import NeonPunkPic from '../../assets/img/generic/neonPunk1.png';
import FantasyArtPic from '../../assets/img/generic/fantasyArtPic1.png';
import AnimePic from '../../assets/img/generic/anime-pic.jpg';
import DigitalArtPic from '../../assets/img/generic/digitalArtPic1.png';
import EnhancePic from '../../assets/img/generic/enhancePic1.png';
import { faCheck, faDownload } from '@fortawesome/free-solid-svg-icons';

const FromImage = () => {
  
  const windowWidth = useWindowDeminsions()
  
  const dispatch = useDispatch()

  const processImage = useSelector((state) => state.processImage)

  const auth = useSelector((state) => state.auth)


  const location = useLocation()
  const currentPath = location.pathname

  
  const history = useHistory();
  const [selected, setSelected] = useState(null);
  const [imageSrc, setImageSrc] = useState(Girl1Pic);
  const [isSmallScreen, setIsSmallScreen] = useState(windowWidth.width < 750);
  const [droppedImage, setDroppedImage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [tooltipStyles, setTooltipStyles] = useState(false);
  const [showStyles, setShowStyles] = useState(false);
  const [selectedStyle, setSelectedStyle] = useState(null);
  const [hover, setHover] = useState(false);
  const toggleTooltipStyles = () => setTooltipStyles(!tooltipStyles);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);


  const [settings, setSettings] = useState({
    text: '',
    numImages: 1,
    imageStrength: 0.50,
    style: 'cinematic lighting, super highly detailed, perfect face, perfect body',
    detail: '',
    lightEffect: '',
    composition: '',
    styleSwitch: false,
    detailSwitch: false,
    lightEffectSwitch: false,
    compositionSwitch: false,
  });


  const styles = [
    { name: "Fantasy Art", image: FantasyArtPic, text: "fantasy-art" },
    { name: "Digital Art", image: DigitalArtPic, text: "digital-art"  },
    { name: "Photographic", image: PhotographicPic, text: "photographic"  },
    { name: "Analog Film", image: AnalogFilmPic, text: "analog-film"  },
    { name: "Anime", image: AnimePic, text: "anime"  },
    { name: "Cinematic", image: CinematicPic, text: "cinematic"  },
    { name: "Comic Book", image: ComicBookPic, text: "comic-book"  },
    { name: "Enhance", image: EnhancePic, text: "enhance"  },
    { name: "Isometric", image: IsometricPic, text: "isometric"  },
    { name: "Neon Punk", image: NeonPunkPic, text: "neon-punk"  },
  
  ]


const handleButtonClick = (type, value) => {
  setSettings((prevState) => {
    // Check if the respective switch is active
    const isSwitchActive = prevState[`${type}Switch`];
    if (isSwitchActive) {
      return {
        ...prevState,
        [type]: value,
      };
    }
    return prevState;
  });
};


  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setDroppedImage(URL.createObjectURL(acceptedFiles[0]));
    }
  };



  const handleGenerate = () => {
    if (!auth) {
      history.push('/authentication/basic/register')
    } else {
      if (droppedImage) {
        // Convert the data URL back to a File object
        fetch(droppedImage)
          .then((res) => res.blob())
          .then((blob) => {
            const file = new File([blob], "image.png", { type: "image/png" });
            dispatch(processImageFunc(settings, file));
          });
          setDroppedImage("")
      }

    }
  };




  useEffect(() => {
    setIsSmallScreen(windowWidth.width < 750);
  }, [windowWidth.width]);


  useEffect(() => {
    if (!isSmallScreen) return;

    let isMounted = true;

    const startAnimation = async () => {
      if (!isMounted) return;

      const focusedImage = document.getElementById('focused-image');
      const sparkles = document.getElementById('sparkles');

      if (focusedImage) {
        focusedImage.classList.add('blur-out');
      }
      await new Promise((resolve) => setTimeout(resolve, 2000));

      if (!isMounted) return;

      setImageSrc(Girl2Pic);
      await new Promise((resolve) => setTimeout(resolve, 1000));

      if (!isMounted) return;

      if (focusedImage) {
        focusedImage.classList.remove('blur-out');
        focusedImage.classList.add('pulse-glow');
      }
      if (sparkles) {
        sparkles.classList.add('show-sparkles');
      }
      await new Promise((resolve) => setTimeout(resolve, 1000));

      if (!isMounted) return;

      if (focusedImage) {
        focusedImage.classList.remove('pulse-glow');
      }
      if (sparkles) {
        sparkles.classList.remove('show-sparkles');
      }

      setImageSrc(Girl1Pic);
      startAnimation();
    };

    startAnimation();

    return () => {
      isMounted = false;
    };
  }, [isSmallScreen]);




  const handleClick = (route) => {
    history.push(route);
  };


  const defaultOptions = {
    loop: 99,
    height: 90,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };


  const handleImageClick = (image) => {
    const imageBuffer = Buffer.from(image.data);
      const base64Image = imageBuffer.toString('base64');
      const dataUrl = `data:image/png;base64,${base64Image}`;
    setSelectedImage(dataUrl);
    setShowModal(true);
  };


 
          const renderImages = () => {
            return processImage?.images?.map((image, index) => {
              // Convert the ArrayBuffer to a base64-encoded data URL
              const imageBuffer = Buffer.from(image.data);
              const base64Image = imageBuffer.toString('base64');
              const dataUrl = `data:image/png;base64,${base64Image}`;
            
            
            
              const handleMouseEnter = () => {
                setHover(true);
              };
            
              const handleMouseLeave = () => {
                setHover(false);
              };
            
              const handleDownloadClick = (e) => {
                e.stopPropagation();
                const link = document.createElement('a');
                link.href = dataUrl;
                link.download = `image_${index}.png`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              };
            
              return (
                <Col xs="12" sm="6" md="4" lg="3" key={index} className="my-3">
                  <Card
                    className={`image-card ${hover ? 'hover' : ''}`}
                    onClick={() => handleImageClick(image)}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <CardImg top width="100%" src={dataUrl} alt={`Image ${index}`} />
                    {hover && (
                      <div className="download-icon" onClick={handleDownloadClick}>
                        <FontAwesomeIcon icon="download"/>
                      </div>
                    )}
                    <CardBody>
                      <CardTitle tag="h5">{`Image ${index}`}</CardTitle>
                    </CardBody>
                  </Card>
                </Col>
              );
            });
          };





            const renderStyleOptions = (handleButtonClick) => {
              return styles.map((style, index) => (
                <Col xs={12} md={6} key={index} className="mb-3">
                  <Card
                    className={`${selectedStyle === index ? 'bg-soft-info' : 'border-secondary'}`}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setSettings({...settings, styleText: style.text})
                      setSelectedStyle(index);
                      handleButtonClick('style', style);
                    }}
                  >
                    <CardBody className="d-flex align-items-center justify-content-center text-center">
                      {selectedStyle === index && (
                        <FontAwesomeIcon icon={faCheck} className="checkmark position-absolute top-0 end-0 me-2 mt-2" />
                      )}
                      <div>
                    <CardImg src={style.image} alt={style.name} className="img-fluid" style={{ minWidth: "70px" }} />
                    <Label className="">{style.name}</Label>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ));
            };


  




const strengthToColor = (value) => {
  const red = Math.round(255 * (1 - value));
  const green = Math.round(255 * value);
  return `rgb(${red}, ${green}, 0)`;
};


  return (

    <Container>
      <Row>
      <Col xs={12} lg={8}>
      { processImage.loading ? (
        <div>
          
          <Row>
      <Col xs={12}>
        <Card>
          <CardBody>
           
            <div className="wizard-lottie-wrapper">
          <div className="wizard-lottie mx-auto">
            <Lottie options={defaultOptions} />
          </div>
        </div>
         <CardText>{`Creating your image(s)`}</CardText>
          </CardBody>
        </Card>
        </Col>
        </Row>
        </div>

      ) : (
        <div>
      <Row>
        <Col className="d-flex justify-content-center">
        <div className="my-button-container">
      <Button
       style={{backgroundColor: 'transparent'}}
        className={`text-black my-nav-button${currentPath === '/from-text' ? ' selected' : ''}`}
        onClick={() => handleClick('/from-text')}
      >
        From Text
      </Button>
      <Button
       style={{backgroundColor: 'transparent'}}
        className={`text-black my-nav-button${currentPath === '/from-image' ? ' selected' : ''}`}
        onClick={() => handleClick('/from-image')}
      >
        From Image
      </Button>
    </div>
        </Col>
      </Row>
        {processImage.images && (
          <div>
              <Row className="mt-5">{renderImages()}</Row>
      <Modal isOpen={showModal} toggle={toggleModal} centered>
        <ModalHeader toggle={toggleModal}>Image Preview</ModalHeader>
        <ModalBody>
          {selectedImage && (
            <img src={selectedImage} className="img-fluid" />
          )}
        </ModalBody>
      </Modal>
          </div>
        )}



        <div>
          {processImage.images.length < 1 && (
      <Row>
        <ExampleTabs />
      </Row>

          )}
      <Row>
      <Col xs={12}>
        <FalconCardHeader
          title="Insert image"
          light={false}
        ></FalconCardHeader>
        <div style={{ height: "250px" }}>
          <FalconDropzone
            onDrop={handleDrop}
            droppedImage={droppedImage}
          ></FalconDropzone>
        </div>
        <Button
          className="mt-3"
          color="primary"
          onClick={handleGenerate}
          disabled={!droppedImage || processImage.loading}
        >
          Generate
        </Button>
      </Col>
    </Row>

        </div>




      </div>
      )}
      </Col> 
      { windowWidth.width > 1000 && (
      <Col md={4} className={windowWidth.width > 1000  ? "d-flex flex-column h-100" : "d-none"}>
          <div>
            <Label className="bold">Number of images:</Label>
            <Slider
              
              min={1}
              max={6}
              value={settings.numImages}
              onChange={(value) => setSettings({...settings, numImages: value})}
            />
            <span className={`badge badge-soft-success rounded-capsule fs--1 mt-1`}> <span className={`badge fs--1 rounded-capsule`}>{settings.numImages}</span></span>
          </div>

          <div>
        <div id="imageStrengthSlider">
        <Label className="bold">Image Strength:</Label>
        </div>
        <Slider
          min={0.10}
          max={0.90}
          step={0.05}
          value={settings.imageStrength}
          onChange={(value) =>
            setSettings({ ...settings, imageStrength: value })
          }
          railStyle={{ backgroundColor: 'transparent' }}
          trackStyle={{ backgroundColor: strengthToColor(settings.imageStrength) }}
          handleStyle={{
            borderColor: strengthToColor(settings.imageStrength),
            backgroundColor: strengthToColor(settings.imageStrength),
          }}
        />
        <Tooltip
          placement="top"
          isOpen={tooltipOpen}
          target="imageStrengthSlider"
          toggle={toggleTooltip}
        >
          Image strength refers to how alike or unlike the returning image will be.
        </Tooltip>
        <span className={`badge badge-soft-success rounded-capsule fs--1 mt-1`}>
          {' '}
          <span className={`badge fs--1 rounded-capsule`}>
            {settings.imageStrength.toFixed(2)}
          </span>
        </span>
      </div>

    
          <Button className={settings.styleSwitch ? "bg-info text-white d-flex justify-content-between btn btn-falcon-primary details-button mt-2" : "d-flex justify-content-between btn btn-falcon-primary details-button mt-2"} onClick={() => setShowStyles(!showStyles)} id="StylesTooltip"  >
            Styles
            <CustomInput
                type="switch"
                id="stylesSwitch"
                name="stylesSwitch"
                onChange={() => { return setSettings({...settings, styleSwitch: !settings.styleSwitch})}}
              />
        
            </Button>
            
            {showStyles && (
              <Row className="mt-2">
                {renderStyleOptions(handleButtonClick, settings.styleSwitch)}
              </Row>
              )}
         
        </Col>
      )}
        </Row>
        { windowWidth.width < 1000 && (

<Row>
<Col xs={12} md={0} className="d-flex flex-column h-100">
  <div>
    <Label className="bold">Number of images:</Label>
    <Slider
      
      min={1}
      max={6}
      value={settings.numImages}
      onChange={(value) => setSettings({...settings, numImages: value})}
    />
    <span className={`badge badge-soft-success rounded-capsule fs--1 mt-1`}> <span className={`badge fs--1 rounded-capsule`}>{settings.numImages}</span></span>
  </div>

  <div>
<div id="imageStrengthSlider">
<Label className="bold">Image Strength:</Label>
</div>
<Slider
  min={0.10}
  max={0.90}
  step={0.05}
  value={settings.imageStrength}
  onChange={(value) =>
    setSettings({ ...settings, imageStrength: value })
  }
  railStyle={{ backgroundColor: 'transparent' }}
  trackStyle={{ backgroundColor: strengthToColor(settings.imageStrength) }}
  handleStyle={{
    borderColor: strengthToColor(settings.imageStrength),
    backgroundColor: strengthToColor(settings.imageStrength),
  }}
/>
<Tooltip
  placement="top"
  isOpen={tooltipOpen}
  target="imageStrengthSlider"
  toggle={toggleTooltip}
>
  Image strength refers to how alike or unlike the returning image will be.
</Tooltip>
<span className={`badge badge-soft-success rounded-capsule fs--1 mt-1`}>
  {' '}
  <span className={`badge fs--1 rounded-capsule`}>
    {settings.imageStrength.toFixed(2)}
  </span>
</span>
</div>


  <Button className={settings.styleSwitch ? "bg-info text-white d-flex justify-content-between btn btn-falcon-primary details-button mt-2" : "d-flex justify-content-between btn btn-falcon-primary details-button mt-2"} onClick={() => setShowStyles(!showStyles)} id="StylesTooltip"  >
    Styles
    <CustomInput
        type="switch"
        id="stylesSwitch"
        name="stylesSwitch"
        onChange={(e) => { return setSettings({...settings, styleSwitch: !settings.styleSwitch})}}
      />

    </Button>
    
    {showStyles && (
      <Row className="mt-2">
        {renderStyleOptions(handleButtonClick, settings.styleSwitch)}
      </Row>
      )}
 
</Col>
</Row>

        ) }
      
    </Container>
  );
};

export default FromImage