import { combineReducers } from 'redux';
import authReducer from './authReducer';
import subscribeCancelReducer from './subscribeCancelReducer';
import invoiceReducer from './invoiceReducer';
import planUpdateStateReducer from './planUpdateReducer';
import cardSecretReducer from './cardSecretReducer';
import confirmIntentReducer from './confirmIntentReducer';
import paymentMethodDeleteReducer from './paymentMethodDeleteReducer';
import tokenCreateReducer from './tokenCreateReducer';
import forgotPasswordCreateReducer from './forgotPasswordCreateReducer';
import imagesReducer from './imagesReducer';
import fromImageTabReducer from './fromImageTabReducer';
import galleryImagesReducer from './galleryImagesReducer';
import clientSecretReducer from './clientSecretReducer';
import processImageReducer from './processImageReducer';
import reduxRouteReducer from './reduxRouteReducer';
import updatePasswordReducer from './updatePasswordReducer';
import paymentReducer from './paymentReducer';
import promptsReducer from './promptsReducer';
import postCreateReducer from './postCreateReducer';



export default combineReducers ({
    auth: authReducer,
    tokenCreate: tokenCreateReducer,
    cardSecret: cardSecretReducer,
    confirmIntent: confirmIntentReducer,
    planUpdateState: planUpdateStateReducer,
    subscribeCancel: subscribeCancelReducer,
    paymentMethodDelete: paymentMethodDeleteReducer,
    invoices: invoiceReducer,
    forgotPasswordCreate: forgotPasswordCreateReducer,
    images: imagesReducer,
    fromImageTabs: fromImageTabReducer,
    galleryImages: galleryImagesReducer,
    clientSecret: clientSecretReducer,
    processImage: processImageReducer,
    reduxRoute: reduxRouteReducer,
    updatePassword: updatePasswordReducer,
    payment: paymentReducer,
    prompts: promptsReducer,
    postCreate: postCreateReducer

});