import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { fetchUser } from '../actions/index';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton, Fade } from '../components/common/Toast';
import DashboardLayout from './DashboardLayout';
import ErrorLayout from './ErrorLayout';
import loadable from '@loadable/component';
import PasswordReset from '../components/auth/split/PasswordReset';

const AuthBasicLayout = loadable(() => import('./AuthBasicLayout'));
const WizardLayout = loadable(() => import('../components/auth/wizard/WizardLayout'));
const AuthCardRoutes = loadable(() => import('../components/auth/card/AuthCardRoutes'));
const AuthSplitRoutes = loadable(() => import('../components/auth/split/AuthSplitRoutes'));

const Layout = () => {
   
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUser());
    AuthBasicLayout.preload();
    WizardLayout.preload();
    AuthCardRoutes.preload();
    AuthSplitRoutes.preload(); // adding the [] after the }, its called a dependecy array
  });

  return (
    <Router fallback={<span />}>
      <Switch>
        <Route path="/password-reset/:ident" exact component={PasswordReset} />
        <Route path="/authentication/card" component={AuthCardRoutes} />
        <Route path="/authentication/split" component={AuthSplitRoutes} />
        <Route path="/authentication/wizard" component={WizardLayout} />
        <Route path="/errors" component={ErrorLayout} />
        <Route path="/authentication/basic" component={AuthBasicLayout} />
        <Route path="/" component={DashboardLayout} />
       
      </Switch>
      <ToastContainer transition={Fade} closeButton={<CloseButton />} position={toast.POSITION.BOTTOM_LEFT} />
    </Router>
  );
};

export default connect(null)(Layout); // the null argument is for mapStateToProps, the second argument is for all the different action creators we want to wire up
