import React, { useContext, useEffect } from 'react';
import { Nav, NavItem, NavLink, Label, UncontrolledTooltip  } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import ProfileDropdown from './ProfileDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import AppContext from '../../context/Context';
import classNames from 'classnames';
import { navbarBreakPoint } from '../../config';
import { fetchUser } from '../../actions/index';
import useWindowDimensions from '../../hooks/useWindowDimensions'

const TopNavRightSideNavItem = () => {
  const windowWidth = useWindowDimensions()
  const { isTopNav, isCombo } = useContext(AppContext);
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
//
//  const creditCreate = useSelector((state => state.creditCreate))
//  const { success:createCredit } = creditCreate
//
//  const bulkSendCreate = useSelector((state) => state.bulkSendCreate)
//  const {success: successBulkSendCreate} = bulkSendCreate
//
//  useEffect(() => {
//    dispatch(fetchUser())
//  }, [createCredit, successBulkSendCreate])
 
  
  return (
    <Nav navbar className="navbar-nav-icons ml-auto flex-row align-items-center">
     { windowWidth.width > 500 && auth && (
     <NavItem>
        <Label className="mt-2 mx-2">Credits: {auth.credits}</Label>
      </NavItem>

     )}
      { windowWidth.width < 500 && (
     <NavItem>
        <Label className="mt-2 mr-2">{auth.credits}</Label>
      </NavItem>

     )}
      {(isCombo || isTopNav) && (
        <NavItem className={classNames(`p-2 px-lg-0 cursor-pointer`, { [`d-${navbarBreakPoint}-none`]: isCombo })}>
          <NavLink tag={Link} to="/changelog" id="changelog">
            <FontAwesomeIcon icon="code-branch" transform="right-6 grow-4" />
          </NavLink>
          <UncontrolledTooltip autohide={false} placement="left" target="changelog">
            Changelog
          </UncontrolledTooltip>
        </NavItem>
      )}
      {

      
    //  <NotificationDropdown />
}
      <ProfileDropdown />
    </Nav>
  );
};




export default TopNavRightSideNavItem;