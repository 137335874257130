import { CLIENT_SECRET_REQUEST, CLIENT_SECRET_SUCCESS, CLIENT_SECRET_FAIL} from '../actions/types';

export default function clientSecretReducer(state = {}, action) {
    switch(action.type) {
        case CLIENT_SECRET_REQUEST:
            return { loading: true }
        case CLIENT_SECRET_SUCCESS:
            return { loading: false, data: action.payload}
        case CLIENT_SECRET_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}