const FETCH_USER = 'fetch_user';

const initialState = {
    users:[],
    loading:true
}

export default function (state = initialState, action) {
    
    switch (action.type) {
        case FETCH_USER:
            return action.payload || false // when we logout, this action.payload is an empty string so lets do || false
     
       
            default:
            return state;
    }
}