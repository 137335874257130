import { IMAGE_DELETE_SUCCESS, FETCH_IMAGES_REQUEST, FETCH_IMAGES_SUCCESS, FETCH_IMAGES_FAILURE, DELETE_IMAGE } from '../actions/types';

const initialState = {
  images: [],
  loading: false,
  error: null,
};

const galleryImagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_IMAGES_REQUEST:
      return { ...state, loading: true };
    case FETCH_IMAGES_SUCCESS:
      const existingIds = new Set(state.images.map((image) => image._id));
      const newImages = action.payload.filter((image) => !existingIds.has(image._id));

      return { ...state, loading: false, images: [...state.images, ...newImages] };
    case FETCH_IMAGES_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case IMAGE_DELETE_SUCCESS:
      return {
        ...state,
        images: state.images.filter((image) => image._id !== action.payload),
      };
    default:
      return state;
  }
};

export default galleryImagesReducer;