import axios from 'axios';

import {

FETCH_REG_USER,
FETCH_USER,
DELETE_USER,
LIST_TEMPLATES,
LIST_INVOICES,
GET_PROFILE_INFO,
LOGOUT_USER,

PROCESS_TEXT_REQUEST,
PROCESS_TEXT_SUCCESS,
PROCESS_TEXT_FAILURE,

//----------------------------------------------------------------------------------------------------------------------------------------------

ACCOUNT_DELETE_REQUEST,
ACCOUNT_DELETE_SUCCESS,
ACCOUNT_DELETE_FAIL,
UPDATE_PRIMARY_NUMBER,

FETCH_IMAGES_REQUEST,
FETCH_IMAGES_SUCCESS,
 FETCH_IMAGES_FAILURE,

 FETCH_PROMPTS_REQUEST,
FETCH_PROMPTS_SUCCESS,
 FETCH_PROMPTS_FAILURE,

 PROCESS_IMAGE_REQUEST,
 PROCESS_IMAGE_SUCCESS,
 PROCESS_IMAGE_FAILURE,

 IMAGE_DELETE_REQUEST,
 IMAGE_DELETE_SUCCESS,
 IMAGE_DELETE_FAIL



} from './types';




export const listInvoices = () => async (dispatch) => {
        try {

               await axios({
               url:"https://my.imageart.ai/invoices",
               method:"GET",
               withCredentials: true
             }).then(res =>  dispatch({type: LIST_INVOICES, payload: res.data}))
        } catch (error) {
                     
        }   }
//--------------------------------------------------------------------Extra Functions--------------------------------------------------------------------




        export const deleteAccount = (id, history) => async (dispatch) => {  
                try {
                        console.log(id)

                        dispatch({type: ACCOUNT_DELETE_REQUEST})
                        await axios({
                                url: `https://my.imageart.ai/account-delete/${id}`,
                                method: "DELETE",
                                withCredentials: true
                        }).then((res) => { history.push('/')})

                        dispatch({type: ACCOUNT_DELETE_SUCCESS})

                        } catch(error) {
                                dispatch({
                                        type: ACCOUNT_DELETE_FAIL,
                                        payload:
                                        error.response && error.response.data.message
                                        ? error.response.data.message
                                        : error.message
                                        })    
                        }
      
        }





        export const deleteImage = (id) => async (dispatch) => {  
                try {
                        console.log(id)

                        dispatch({type: IMAGE_DELETE_REQUEST})
                        await axios({
                                url: `https://my.imageart.ai/image-delete`,
                                method: "POST",
                                data: {_id: id},
                                withCredentials: true
                        }).then((res) => { return dispatch({type: IMAGE_DELETE_SUCCESS, payload: res.data.deletedImageId}) })
                 

                        } catch(error) {
                                dispatch({
                                        type: IMAGE_DELETE_FAIL,
                                        payload:
                                        error.response && error.response.data.message
                                        ? error.response.data.message
                                        : error.message
                                        })    
                        }
      
        }



        export const fetchUser = () => {
                return async function(dispatch) { // we need dispatch prop
               await axios
               .get('/api/current_user')
               .then( res => dispatch({type: FETCH_USER, payload: res.data}))
               };
        };

        export const LogoutUser = () => {
                return async function(dispatch) {
                        await axios
                        .get('https://my.imageart.ai/api/logout')
                        .then( res => dispatch({ type: LOGOUT_USER, payload: res}))
                }
        }



        export const fetchRegUser = () => {
                return async function(dispatch) {
                        await axios
                        .get('/api/users')
                        .then(res => dispatch({type: FETCH_REG_USER, payload: res.data}))
                }
        }

        export const deleteUser = (id) => {  
                return async function (dispatch) {
                        console.log(id)
                        const res = await axios
                        .delete(`https://my.imageart.ai/api/users/${id}`)
                        dispatch({type: DELETE_USER, payload: res.data })
                }
        }

                         // sending stripe token to backend
        export const handleCheckout = () => async dispatch => {
                await axios({
                        url:"https://my.imageart.ai/api/stripe",
                        method:"POST",
                        withCredentials: true
                      }).then(res =>
                              dispatch({type: FETCH_USER, payload: res})
                      )
        };

        export const getProfileInfo = () => {
                return async function(dispatch) {
                await axios({
                        url:`https://my.imageart.ai/profile/update`,
                        method:"GET", 
                        withCredentials: true
                        }).then(res => 
                        dispatch({type: GET_PROFILE_INFO, payload: res.data }))
                }
        }


        export const processText = (settings) => async (dispatch) => {
                try {
                dispatch({ type: PROCESS_TEXT_REQUEST})
                  const response = await axios.post(
                    'https://my.imageart.ai/process-text',
                    { settings },
                    { withCredentials: true }
                  );
                  dispatch({ type: PROCESS_TEXT_SUCCESS, payload: response.data.images });
                } catch (error) {
                  dispatch({ type: PROCESS_TEXT_FAILURE, payload: error });
                }
              };



              export const processImageFunc = (settings, image) => async (dispatch) => {
                console.log("settings", settings)
                console.log('image', image)
                try {
                dispatch({ type: PROCESS_IMAGE_REQUEST})
                  const formData = new FormData();
                  formData.append('image', image);
                  formData.append('settings', JSON.stringify(settings))
              
                  const response = await axios.post(
                    'https://my.imageart.ai/process-image',
                    formData,
                    {
                      withCredentials: true,
                      headers: { 'Content-Type': 'multipart/form-data' },
                      data: { settings }
                    }
                  );
                  console.log("response", response)
                  dispatch({ type: PROCESS_IMAGE_SUCCESS, payload: response.data.images });
              
                  // Handle the response, e.g., update the state with the new image
                  console.log(response.data);
                } catch (error) {
                  console.error('Error:', error.message);
                  dispatch({ type: PROCESS_IMAGE_FAILURE, payload: error });
                }
              };




              
              export const fetchImages = (userId, page, limit) => async (dispatch) => {
                try {
                        dispatch({ type: FETCH_IMAGES_REQUEST})
                  const response = await axios.get(`https://my.imageart.ai/images/${userId}`, {
                    withCredentials: true,
                    params: {
                      page: page,
                      limit: limit,
                    },
                  });
              
                  dispatch({ type: FETCH_IMAGES_SUCCESS, payload: response.data });
                } catch (error) {
                  dispatch({ type: FETCH_IMAGES_FAILURE, payload: error });
                }
              };



              export const fetchPrompts = () => async (dispatch) => {
                try {
                        dispatch({ type: FETCH_PROMPTS_REQUEST})
                  const response = await axios.get(`https://my.imageart.ai/list-prompts`, {
                    withCredentials: true
                  });
              
                  dispatch({ type: FETCH_PROMPTS_SUCCESS, payload: response.data });
                } catch (error) {
                  dispatch({ type: FETCH_PROMPTS_FAILURE, payload: error });
                }
              };