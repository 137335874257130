import { PROCESS_IMAGE_REQUEST, PROCESS_IMAGE_SUCCESS, PROCESS_IMAGE_FAILURE} from '../actions/types';




const initialState = {
  images: [],
  loading: false,
  error: null
}

const processImageReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROCESS_IMAGE_REQUEST:
      return { ...state, loading: true };
    case PROCESS_IMAGE_SUCCESS:
      return { ...state, images: action.payload, loading: false };
    case PROCESS_IMAGE_FAILURE:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};

export default processImageReducer;














// Function to convert an image to an ArrayBuffer
//const imageToBuffer = async (imageSrc) => {
//  return new Promise((resolve, reject) => {
//    const img = new Image();
//    img.crossOrigin = 'Anonymous';
//    img.onload = () => {
//      const canvas = document.createElement('canvas');
//      const ctx = canvas.getContext('2d');
//      canvas.width = img.width;
//      canvas.height = img.height;
//      ctx.drawImage(img, 0, 0);
//      canvas.toBlob((blob) => {
//        const reader = new FileReader();
//        reader.onloadend = () => resolve(new Uint8Array(reader.result));
//        reader.onerror = reject;
//        reader.readAsArrayBuffer(blob);
//      });
//    };
//    img.src = imageSrc;
//  });
//};
//
//const generateInitialImages = async () => {
//  const buffers = [];
//  for (let i = 0; i < 6; i++) {
//    const buffer = await imageToBuffer(testPic);
//    buffers.push(buffer);
//  }
//  return buffers;
//};
//
//const initialState = {
//  images: generateInitialImages(),
//  error: null,
//};

//const processImageReducer = (state = initialState, action) => {
//  switch (action.type) {
//    case PROCESS_IMAGE_SUCCESS:
//      return { ...state, images: action.payload };
//    case PROCESS_IMAGE_FAILURE:
//      return { ...state, error: action.payload };
//    default:
//      return state;
//  }
//};
//
//export default processImageReducer;